import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        products: [],
        pagination: {},
        selectedIds: [],
        filter: {
            limit: 5,
            page: 1,
            orderBy: 'id',
            sorting: "DESC",
            brandId: null,
            store_id: [],
            in_store: '',
            in_archived: '',
            product: {
                id: '',
                barcode: '',
                name: ''
            }
        },
    },
    getters: {
        _products(state) {
            return state.products;
        },
        _pagination(state) {
            return state.pagination;
        },
        _selectedIds(state) {
            return state.selectedIds;
        },
        _filter(state) {
            return state.filter;
        },
    },
    mutations: {
        setProducts(state, products) {
            state.products = products;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        }
    },
    actions: {
        getFilter(context) {
            context.commit('setProducts', [])
            return axios.post('trendyol/create-product/filter', context.state.filter)
                .then(res => {
                    if (res.data.status === 'success') {
                        context.commit('setProducts', res.data.content.data)
                        delete res.data.content.data;
                        context.commit('setPagination', res.data.content);
                        return true
                    }
                    return false;
                })
                .catch(() => {
                    return false
                })
        }
    }
}
