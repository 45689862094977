import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        oldRack: null,
        newRack: null,
        products: [],
        stocktakingType: 'full',

        oldRackProducts: [], //kullanım dışı
        newRackProducts: [],//kullanım dışı
    },
    getters: {
        _oldRack(state) {
            return state.oldRack
        },
        _newRack(state) {
            return state.newRack
        },
        _products(state) {
            return state.products;
        },
        _stocktakingType(state) {
            return state.stocktakingType;
        },
        _oldRackProducts(state) { //kullanım dışı
            return state.oldRackProducts;
        },
        _newRackProducts(state) { //kullanım dışı
            return state.newRackProducts;
        },
    },
    mutations: {
        setOldRack(state, rack) {
            state.oldRack = rack;
        },
        setNewRack(state, rack) {
            state.newRack = rack;
        },
        setProducts(state, products) {
            state.products = products;
        },
        createOrUpdateProduct(state, payload) {
            let index = state.products.findIndex(item => item.id === payload.id);
            if (index > -1) state.products.splice(index, 1, payload);
            else state.products.push(payload)
        }
    },
    actions: {
        getOldRackProducts(context, id) {
            return axios.get(`/v2/stock/stocktaking/${id}`)
                .then(res => {
                    context.commit('setProducts', res.data.content)
                    return res.data;
                })
        },
        storeNewProducts(context, paylaod) {
            return axios.post('/v2/stock/stocktaking', paylaod)
                .then(res => {
                    context.commit('createOrUpdateProduct', res.data.content)
                    return res.data;
                })
        },
        updateNewProducts(context, payload) {
            return axios.put(`v2/stock/stocktaking/${payload.id}`, payload)
                .then(res => {
                    return res.data
                })
        },

    }
}