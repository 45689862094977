import axios from "@/libs/axios";
import Vue2Filters from 'vue2-filters'

export default {
    namespaced: true,
    state: {
        search: {},
        stores: [],
        selectedEditStore: {},
        supplierParams: []
    },
    getters: {
        _search(state) {
            return state.search;
        },
        _stores(state) {
            return state.stores;
        },
        _selectedEditStore(state) {
            return state.selectedEditStore
        },
        _supplierParams(state) {
            return state.supplierParams
        }
    },
    mutations: {
        setStores(state, stores) {
            state.stores = stores
        },
        addStore(state, store) {
            state.stores.push(store);
        },
        editStore(state, store) {
            let foundIndex = state.stores.findIndex(item => parseInt(item.id) == parseInt(store.id));
            state.stores.splice(foundIndex, 1, store);
        },
        addaddShippingScale(state, id) {
            let store = state.stores.find(store => parseInt(store.id) == parseInt(id));
            store.shipping_scales.push({maxPrice: null, price: null});
        },
        deleteShippingScale(state, data) {
            let store = state.stores.find(store => parseInt(store.id) == parseInt(data.storeId));
            store.shipping_scales.splice(data.indexNo, 1);
        },
        addDesiScale(state, id) {
            let store = state.stores.find(store => parseInt(store.id) == parseInt(id));
            store.desi_scales.push({desi: null, price: null});
        },
        deleteDesiScale(state, data) {
            let store = state.stores.find(store => parseInt(store.id) == parseInt(data.storeId));
            store.desi_scales.splice(data.indexNo, 1);
        },
        addScale(state, id) {
            let store = state.stores.find(store => parseInt(store.id) == parseInt(id));
            store.scales.push({maxCost: null, profitMin: null, profitMax: null, profitFix: null});
        },
        deleteScale(state, data) {
            let store = state.stores.find(store => parseInt(store.id) == parseInt(data.storeId));
            store.scales.splice(data.indexNo, 1);
        },
        selectedEditStore(state, store) {
            state.selectedEditStore = {...store}
        },
        setSupplierParams(state, params) {
            state.supplierParams = params
        },
        addSupplierParams(state, params) {
            state.supplierParams.push(params);
        },
        addSupplierParamsDeadline(state, supplierId) {
            state.supplierParams.forEach(params => {
                if (params.supplier_id == supplierId) {
                    params.deadlines.push({
                        maxStock: 0,
                        deadline: null
                    })
                }
            })
        },
        delSupplierParamsDeadline(state, data) {
            state.supplierParams.forEach(params => {
                if (params.supplier_id == data.supplierId) {
                    params.deadlines.splice(data.itemId, 1);
                }
            })
        },
        orderSupplierParamsDeadline(state, supplierId) {
            state.supplierParams.forEach(params => {
                if (params.supplier_id == supplierId) {
                    let items = [];
                    params.deadlines.forEach(deadline => {
                        items.push({
                            maxStock: parseInt(deadline.maxStock),
                            deadline: parseInt(deadline.deadline)
                        });
                    })
                    params.deadlines = items;
                    params.deadlines = Vue2Filters.mixin.methods.orderBy(params.deadlines, 'maxStock')
                }
            });
        }
    },
    actions: {
        stores(context) {
            return axios.get('/store', context.state.search)
                .then(res => {
                    context.commit('setStores', res.data)
                    return res.data;
                })
                .catch(error => {
                        alert('Mağaza Listesi Çekme Hatası')
                        return error
                    }
                )
        },
        addNew(context, data) {
            return axios.post('/store', data)
                .then(res => {
                    context.commit('addStore', res.data);
                    return res.data
                })
                .catch(error => {
                    alert('Kategori Ekleme Hatası')
                    return error;
                })
        },
        edit(context, data) {
            return axios.put('/store/' + data.id, data)
                .then(res => {
                    context.commit('editStore', res.data)
                    return {
                        title: 'Kayıt Başarılı',
                        variant: 'success',
                        icon: 'SaveIcon',
                        text: res.data.store_name + ' güncellendi'
                    }
                })
                .catch(() => {
                    alert('Mağaza düzenleme hatası');
                    return {
                        title: 'Kayıt Başarısz',
                        variant: 'danger',
                        icon: 'SaveIcon',
                        text: 'güncelleme yapılamadı'
                    };
                })

        },
        supplierParams(context, storeId) {
            return axios.get('/store-supplier-params/' + storeId)
                .then(res => {
                    context.commit('setSupplierParams', res.data)
                    return res.data;
                })
                .catch(error => {
                    alert('Tedarikçi parametreleri alma hatası')
                    return error;
                })
        },
        saveSupplierParams(context, data) {
            return axios.post('/store-supplier-params/update-all', data)
                .then(res => {
                    context.commit('setSupplierParams', res.data)
                    return {
                        title: 'Kayıt Başarılı',
                        icon: 'SaveIcon',
                        text: '👋 Parametreler başarı ile kayıt edildi',
                        variant: 'success'
                    }
                })
                .catch(error => {
                    alert('Mağaza tedarikçi parametre kayıt hatası')
                    return {
                        title: 'Kayıt Başarısız',
                        icon: 'XOctagonIcon',
                        text: '👋Bir hata oluştu',
                        variant: 'danger'
                    }
                })
        }
    }
}
