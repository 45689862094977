import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        agreements: [],
    },
    getters: {
        _agreements(state) {
            return state.agreements
        }
    },
    mutations: {
        setAgreements(state, agreements) {
            state.agreements = agreements
        }
    },
    actions: {
        getAll(context) {
            return axios.get('/agreement')
                .then(res => {
                    context.commit('setAgreements', res.data.agreements);
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                    };
                })
                .catch(() => {
                    return {
                        title: 'Mutabakat çekme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    };
                })
        }
    }
}
