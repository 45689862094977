import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        products: [],
        pagination: null,
        selectedItems: [],
        multiprocessing: {
            select: '',
            process: '',
        },
        search: {
            status: [],
            limit: 5,
            merchant_sku: "",
            product_mp_id: "",
            product_name: "",
            isSingleMulti: 'all',
            isMultiple: 'all',
            brands: [],
            suppliers: [],
            product: {
                barcode: "",
                id: "",
                name: "",
                stock: 0
            }
        },
    },
    getters: {
        _products(state) {
            return state.products;
        },
        _pagination(state) {
            return state.pagination
        },
        _search(state) {
            return state.search;
        },
        _multiprocessing(state) {
            return state.multiprocessing
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
        setPagination(state, data) {
            delete data.data;
            state.pagination = data;
        },
        deleteBrand(state, key) {
            state.search.brands.splice(key, 1);
        },
        setProductsPivotStatus(state, ids) {
            ids.forEach(id => {
                if (id.status) {
                    state.products.forEach(hbProduct => {
                        if (hbProduct.id == id.hb_product_id) {
                            hbProduct.products2.forEach(product => {
                                if (product.id == id.product_id) {
                                    product.pivot.status = id.status;
                                }
                            })
                        }
                    })
                }
            })
        }
    },
    actions: {
        getProducts(context) {
            return axios.post('/hepsiburada/product-matching/all', context.state.search)
                .then(res => {
                    context.commit('setProducts', res.data.hbProducts.data)
                    context.commit('setPagination', res.data.hbProducts)
                    return true;
                })
                .catch(() => {
                    alert('HB Ürün Eşleşme listesi çekme hatası')
                    return true
                })
        },
        setProductsPivotStatus(context, ids) {
            return axios.post('/hepsiburada/product-matching/set-pivot-all', {ids})
                .then(res => {
                    context.commit('setProductsPivotStatus', res.data.data)
                    return 1;
                })
                .catch(() => {
                    alert('Statüleri değiştirme hatası')
                    return 1;
                })
        }
    }
}
