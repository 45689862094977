import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        categories: [],
        search: {},
        selected: {"name": "", "parent_id": null}
    },
    getters: {
        _categories(state) {
            return state.categories
        }, _selected(state) {
            return state.selected
        }
    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories
        },
        setSelected(state, category) {
            state.selected = category
        },
        changeCategory(state, category) {
            const foundIndex = state.categories.findIndex(item => parseInt(item.id) == parseInt(category.id))
            state.categories.splice(foundIndex, 1, category)
        },
        addCategory(state, category) {
            const foundIndex = state.categories.findIndex(item => parseInt(item.id) == parseInt(category.parent_id))
            state.categories.splice(foundIndex + 1, 0, category)
        },
        delCategory(state, id) {
            const foundIndex = state.categories.findIndex(item => parseInt(item.id) == parseInt(id))
            state.categories.splice(foundIndex, 1)
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/category/all', context.state.search)
                .then(res => {
                    context.commit('setCategories', res.data)
                    return 'success';
                })
                .catch(() => {
                    alert('Kategori Çekme Başarısız')
                    return 'danger';
                })
        },
        addNewCategory(context, data) {
            return axios.post('/category', data)
                .then(res => {
                    context.commit('addCategory', res.data);
                    return res.data
                })
                .catch(() => {
                    alert('Kategori Ekleme Hatası')
                })
        },
        edit(context, data) {
            return axios.put('/category/' + data.id, data)
                .then(res => {
                    context.commit('changeCategory', res.data);
                    return res.data
                })
                .catch(error => {
                    console.log('Kategori Düzeltme Hatası')
                    return error;
                })
        },
        destroy(context, id) {
            return axios.delete('/category/' + id)
                .then(res => {
                    if (res.data) {
                        context.commit('delCategory', id);
                        return res.data
                    }
                })
                .catch(error => {
                    alert('Kategori Silme Hatası')
                    return error;
                })
        }
    }
}
