import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        products: [],
        pagination: null,
        selectedProducts: [],
        search: {
            stores: [],
            status: [],
            limit: 20,
            merchant_sku: "",
            product_mp_id: "",
            product_name: "",
            isSingleMulti: 'all',
            brands: [],
            suppliers: [],
            product: {
                barcode: "",
                id: "",
                name: "",
                stock: 0
            }
        },
    },
    getters: {
        _products(state) {
            return state.products;
        },
        _selectedProducts(state) {
            return state.selectedProducts
        },
        _search(state) {
            return state.search;
        },
        _pagination(state) {
            return state.pagination
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
        setProductPivotStatus(state, data) {
            state.products.forEach(tyProduct => {
                tyProduct.products2.forEach(product => {
                    if (tyProduct.id == data.ty_product_id && product.id == data.product_id) {
                        product.pivot.status = data.status
                    }

                });
            })
        },
        setPagination(state, data) {
            delete data.data;
            state.pagination = data;
        },
        setProductsPivotStatus(state, data) {
            data.forEach(item => {
                state.products.forEach(tyProduct => {
                    if (tyProduct.id == item.ty_product_id) {
                        tyProduct.products2.forEach(product => {
                            if (product.id == item.product_id) {
                                product.pivot = item
                            }
                        })
                    }
                })
            })
        },
        deleteBrand(state, key) {
            state.search.brands.splice(key, 1);
        },
    },
    actions: {
        getProducts(context) {
            return axios.post('/trendyol/product/all', context.state.search)
                .then(res => {
                    context.commit('setProducts', res.data.tyProducts.data)
                    context.commit('setPagination', res.data.tyProducts)
                    return true;
                })
                .catch(() => {
                    alert('TY Ürün listesi çekme hatası')
                    return true
                })
        },
        setProductPivotStatus(context, data) {
            axios.post('/trendyol/product/set-pivot', data)
                .then(res => {
                    if (res.data > 0) {
                        context.commit('setProductPivotStatus', data)
                    }
                    return 1;
                })
                .catch(() => {
                    alert('Statü değiştirme hatası')
                    return 1;
                })
        },
        setProductsPivotStatusData(context, ids) {
            axios.post('/trendyol/product/set-pivot-all', {ids})
                .then(res => {
                    context.commit('setProductsPivotStatus', res.data.data)
                    return 1;
                })
                .catch(() => {
                    alert('Statüleri değiştirme hatası')
                    return 1;
                })
        },
        setProductsPivotStatus(context) {
            let ids = [];
            context.state.products.forEach(tyProduct => {
                tyProduct.products2.forEach(product => {
                    if (product.matching) {
                        ids.push({
                            ty_product_id: tyProduct.id,
                            product_id: product.id,
                            status: 2
                        })
                    }
                })
            })
            axios.post('/trendyol/product/set-pivot-all', {ids})
                .then(res => {
                    context.commit('setProductsPivotStatus', res.data.data)
                    return 1;
                })
                .catch(() => {
                    alert('Statüleri değiştirme hatası')
                    return 1;
                })
        }

    }
}
