import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        authors: [],
    },
    getters: {
        _authors(state) {
            return state.authors;
        }
    },
    mutations: {
        setAuthors(state, authors) {
            state.authors = authors
        }
    },
    actions: {
        all(context) {
            return axios.get('/supplier-author')
                .then(res => {
                    context.commit("setAuthors", res.data)
                    return res.data;
                })
                .catch(error => {
                    alert('Tedarikçi yazarları çekme hatası')
                    return error;
                })
        }
    }
}
