import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        companies: [],
    },
    getters: {
        _companies(state) {
            return state.companies;
        }
    },
    mutations: {
        setCompanies(state, companies) {
            state.companies = companies
        }
    },
    actions: {
        getAll(context) {
            axios.get('/company/cargo')
                .then(res => {
                    context.commit('setCompanies', res.data.companies)
                })
                .catch(() => {
                    alert('Kargo firması çekme hatası')
                })
        }
    }
}
