export default [
    {
        path: '/question',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'question',
                component: () => import('@/views/question/QuestionHome.vue'),
                meta: {},
            },
            {
                path: 'answer',
                name: 'question-answer',
                component: () => import('@/views/question/readyanswer/ReadyAnswerHome.vue'),
                meta: {},
            },
        ]
    },
]
