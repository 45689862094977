import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        stocks: [],
    },
    getters: {
        _stocks(state) {
            return state.stocks
        },
    },
    mutations: {
        setStocks(state, stocks) {
            state.stocks = stocks
        },
        addStock(state, stock) {
            state.stocks.push(stock)
        },
        editStocks(state, stock) {
            state.stock.forEach(item => {
            })
        },
    },
    actions: {
        addNewStock(context, data) {
            return axios.get(`stock/card/${data.search_barcode}`)
                .then(res => {
                    const clanData = {...data}
                    clanData.product = res.data.product
                    context.commit('addStock', clanData)
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                        product: res.data.product
                    }
                })
                .catch(() => ({
                    title: 'Ürün geldi silme hatası',
                    variant: 'danger',
                    icon: 'XCircleIcon',
                    product: null
                }))
        },
        saveNewStocks(context, data) {
            axios.post('stock/card/all', data)
                .then(res => {
                    context.commit('setStocks', res.data)
                })
                .catch(() => ({
                    title: 'Ürün geldi silme hatası',
                    variant: 'danger',
                    icon: 'XCircleIcon',
                }))
        },
        getAll22(context) {
            return axios.get('/stock/all')
                .then(res => {
                    context.commit('setProducts', res.data)
                })
                .catch(() => {
                    alert('stok seçkme hatası')
                })
        },
        addNewProduct22(context, product) {
            return axios.post('/stock/taking/new', product)
                .then(res => {
                    context.commit('addProducts', res.data)
                    return true
                })
                .catch(() => {
                    alert('stok sayım ekleme hatası')
                    return true
                })
        },
        updateProdcut22(context, data) {
            return axios.put(`/stock/taking/edit/${data.id}`, data)
                .then(res => {
                    context.commit('editProduct', res.data)
                    return true
                })
                .catch(() => {
                    alert('stok düzeltme hatası')
                    return true
                })
        },
    },
}
