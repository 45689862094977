import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        orderQuestions: [],
    },
    getters: {
        _orderQuestions(state) {
            return state.orderQuestions;
        },
        _orderQuestionsCount(state) {
            let count = 0;
            state.orderQuestions.forEach((item) => {
                count += parseInt(item.count);
            })
            return count
        },
        isError(state) {
            let error = false
            state.orderQuestions.forEach((item) => {
                if (!item.status) {
                    error = true
                }
            })
            return error
        }
    },
    mutations: {
        setOrderQuestions(state, questions) {
            state.orderQuestions = questions
        }
    },
    actions: {
        getAll(context, data) {
            axios.get('/order-question')
                .then(res => {
                    context.commit('setOrderQuestions', res.data.content)
                })
        }
    }
}
