import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        users: [],
    },
    getters: {
        _users(state) {
            return state.users;
        },
    },
    mutations: {
        setUsers(state, users) {
            state.users = users
        },
    },
    actions: {
        getall(context) {
            axios.get('/user')
                .then(res => {
                    context.commit('setUsers', res.data.users)
                })
                .catch(() => {
                    alert('Üye listesi çekme hatası')
                })
        },
    }
}
