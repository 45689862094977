import Vue from 'vue'
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Vue2Filters from 'vue2-filters'
import VueSweetalert2 from 'vue-sweetalert2';

import router from './router'
import store from './store'
import App from './App.vue'

import VModal from "vue-js-modal";
import VueHtmlToPaper from 'vue-html-to-paper';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueHtmlToPaper, {
    name: 'etiket',
    specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=yes',],
    styles: ['/etiket.css',],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
});

// Vue-Pusher'ı içe aktar ve kullan
Vue.use(require('vue-pusher'), {
    api_key: '749e2d4fafa14da3dd74',
    options: {
        cluster: 'eu',
        encrypted: true,
    }
});

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2);

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Vue2Filters)
Vue.use(VModal, {dynamic: true, injectModalsContainer: true});

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
