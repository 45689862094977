import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        activeStock: [],
    },
    getters: {
        _activeStock(state) {
            return state.activeStock;
        }
    },
    mutations: {
        setActiveStock(state, stocks) {
            state.activeStock = stocks;
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/v2/stock/active/all')
                .then(res => {
                    context.commit('setActiveStock', res.data.content.data)
                    return res.data;
                })
        },
        store(context, product) {
            return axios.post('/v2/stock/active', product)
                .then(res => {
                    return res.data;
                })
        },
        updateActiveStock(context, payload) {
            return axios.post(`/v2/stock/active/${payload.id}`, payload)
                .then(res => {
                    return res.data;
                })
        }
    }
}