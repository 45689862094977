import axios from '@/libs/axios'
import log from 'echarts/src/scale/Log'

export default {
    namespaced: true,
    state: {
        companyList: [],
    },
    getters: {
        _companyList(state) {
            return state.companyList;
        }
    },
    mutations: {
        setCompanyList(state, companyList) {
            state.companyList = companyList
        }
    },
    actions: {
        getCompanyList(context) {
            axios.get('/company')
                .then(res => {
                    context.commit('setCompanyList', res.data.content)
                })
                .catch(() => {
                    alert('Kargo firması çekme hatası')
                })
        }
    },
}
