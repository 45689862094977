import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        readyAnswers: [],
    },
    getters: {
        _readyAnswers(state) {
            return state.readyAnswers
        }
    },
    mutations: {
        setReadyAnswers(state, readyAnswers) {
            state.readyAnswers = readyAnswers
        },
        changeReadyAnswer(state, data) {
            let findIndex = state.readyAnswers.findIndex(item => item.id === data._id)
            if (findIndex > -1) {
                state.readyAnswers.splice(findIndex, 1, data)
            }
        },
        addReadyAnswer(state, data) {
            state.readyAnswers.push(data)
        },
        deleteReadyAnswer(state, id) {
            let findIndex = state.readyAnswers.findIndex(item => item.id === id)
            if (findIndex > -1) {
                state.readyAnswers.splice(findIndex, 1)
            }
        }
    },
    actions: {
        getAll(context) {
            return axios.get('/readyanswer')
                .then(res => {
                    context.commit('setReadyAnswers', res.data.content)
                    return res.data
                })
        },
        storeReadyAnswer(context, data) {
            return axios.post('/readyanswer', data)
                .then(res => {
                    context.commit('addReadyAnswer', res.data.content)
                    return res.data
                })
        },
        updateReadyAnswer(context, data) {
            return axios.put(`/readyanswer/${data.id}`, data)
                .then(res => {
                    context.commit('changeReadyAnswer', data)
                    return res.data
                })
        },
        destroyReadyAnswer(context, id) {
            return axios.delete(`/readyanswer/${id}`)
                .then(res => {
                    context.commit('deleteReadyAnswer', id)
                    return res.data
                })
        }
    }
}