import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        brands: [],
        fihrist: [],
        editBrand: {},
        search: {}
    },
    getters: {
        _brands(state) {
            return state.brands;
        },
        _fihrist(state) {
            var list = [];
            var liste = [];
            state.fihrist.forEach(brand => {
                let firstChar = brand.name.charAt(0).toUpperCase();
                if (!list.includes(firstChar)) {
                    list.push(firstChar)
                    liste.push({
                        firstChar,
                        brands: [brand]
                    })
                } else {
                    liste.forEach(list1 => {
                        if (list1.firstChar === firstChar) {
                            list1.brands.push(brand)
                        }
                    })
                }
            })
            return liste;
        },
        _editBrand(state) {
            return state.editBrand;
        },
        _search(state) {
            return state.search
        }
    },
    mutations: {
        setBrands(state, brands) {
            state.brands = brands;
        },
        setFihrist(state, brands) {
            state.fihrist = brands
        },
        addBrand(state, brand) {
            state.brands.push(brand)
        },
        setEditBrand(state, brand) {
            state.editBrand = {...brand}
        },
        setBrand(state, brand) {
            let foundIndex = state.brands.findIndex(item => item.id == brand.id);
            if (foundIndex > -1) state.brands.splice(foundIndex, 1, brand)
        },
        delBrand(state, brandId) {
            let foundIndex = state.brands.findIndex(item => item.id == brandId);
            if (foundIndex > -1) state.brands.splice(foundIndex, 1)
        }
    },
    actions: {
        getAll(context) {
            return axios.post('product/brands/list', context.state.search)
                .then(res => {
                    if (context.state.search.storeId)
                        context.commit('setFihrist', res.data);
                    else
                        context.commit('setBrands', res.data);
                    return true;
                })
                .catch(() => {
                    console.log('Brand List Error')
                    return true;
                })
        },
        storeBrand(context, brand) {
            return axios.post('/product/brands', brand)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setEditBrand', res.data.data)
                        context.commit('addBrand', res.data.data)
                    }
                    return res.data;
                })
                .catch(() => {
                    return {
                        message: 'Marka Ekleme Hatası',
                    }
                })
        },
        updateBrand(context, brand) {
            return axios.put('/product/brands/' + brand.id, brand)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setEditBrand', res.data.data)
                        context.commit('setBrand', res.data.data)
                    }
                    return res.data;
                })
                .catch(() => {
                    return {
                        message: 'Marka Güncelleme Hatası',
                    }
                })
        },
        deleteBrand(context, brandId) {
            return axios.delete('/product/brands/' + brandId)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('delBrand', brandId)
                    }
                    return res.data;
                })
                .catch(() => {
                    return {
                        message: 'Marka Silme Hatası',
                    }
                })
        }
    }
}
