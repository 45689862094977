import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        statuses: [],
    },
    getters: {
        _orderStatuses(state) {
            return state.statuses
        }
    },
    mutations: {
        setStatuses(state, statuses) {
            state.statuses = statuses
        },
    },
    actions: {
        getAll(context) {
            return axios.get('/order/status')
                .then(res => {
                    context.commit('setStatuses', res.data)
                })
                .catch(() => {
                    alert('sipariş statü çekme hatası')
                })
        }
    }
}
