import axios from "@/libs/axios";
import Vue2Filters from 'vue2-filters'

export default {
    namespaced: true,
    state: {
        products: [],
        selecetedProducts: [],
        pagination: {},
        search: {
            single: true,
            multiple: true,
            statuses: [],
            limit: 50,
            orderById: 'rank',
            orderBy: '-1',
            sortings: [
                {key: 'Sıra', val: 'id'},
                {key: 'Rank', val: 'rank'},
            ],
            sortingList: [
                {key: 'A-Z', val: '1'},
                {key: 'Z-A', val: '-1'},
            ],
        },
        filter: {
            limit: 5,
            store_ids: [7],
            amazon_fba: "",
            page: 1,
            preOrder: {
                statuses: [],
            },
            azProduct: {
                merchant_sku: '',
            },
            product: {
                id: '',
                barcode: '',
                name: '',
                brandIds: [],
            },
            supplier: {
                ids: [],
                stock: null,
            },
            az_store_fba_products: {
                is_fba: '',
                fn_sku: '',
                seller_sku: '',
                total_quantity_min: null,
                total_quantity_max: null,
                fulfillable_quantity_min: null,
                fulfillable_quantity_max: null,
                inbound_working_quantity_min: null,
                inbound_working_quantity_max: null,
                inbound_shipped_quantity_min: null,
                inbound_shipped_quantity_max: null,
                inbound_receiving_quantity_min: null,
                inbound_receiving_quantity_max: null,
            },
            sales_ranks: {
                rank: {min: null, max: null},
            },
            buybox_list: {
                scan_date: '',
            },
            by_amazon: {
                min: null,
                max: null
            },
            by_merchant: {
                min: null,
                max: null
            },
            sorting: "DESC"
        }
    },
    getters: {
        _searchProducts(state) {
            let products;

            products = state.products.filter(product => {


                //orderby için sıralama değerlerini alalım
                if (state.search.orderById === 'rank') {
                    product.sales_ranks.forEach(salesRank => {
                        if (!salesRank.product_category.parent_id) {
                            product.rank = salesRank.sales_rank
                        }
                    })
                }


                let conditionCount = 0; //toplam şart sayısı
                let isTrueCount = 0; // toplam true sayısı

                if (state.search.statuses.length > 0) {
                    conditionCount++;
                    product.pre_orders.filter(preOrder => {
                        if (state.search.statuses.includes(preOrder.pre_order_status_id)) isTrueCount++;
                    })
                }

                if (!(state.search.single && state.search.multiple)) {
                    conditionCount++;
                    let productLength = product.az_product.products.length;
                    if (productLength == 1 && state.search.single) isTrueCount++;
                    if (productLength > 1 && state.search.multiple) isTrueCount++;
                }
                if (conditionCount > 0) { //toplam şart sayısı varsa kontrol edelim
                    if (isTrueCount < conditionCount) return false; // toplam şarttan az true farsa ele girsin
                    else return true;
                } else return true; //toplam şart sıfırsa bir filtre yok hepsini göster
            });
            //sıralama ve adet limiti yapalım
            return Vue2Filters.mixin.methods.limitBy(
                Vue2Filters.mixin.methods.orderBy(
                    products,
                    state.search.orderById,
                    parseInt(state.search.orderBy)
                ),
                state.search.limit
            )
        },
        _products(state) {
            return state.products;
        },
        _search(state) {
            return state.search;
        },
        _selecetedProducts(state) {
            return state.selecetedProducts
        },
        _pagination(state) {
            return state.pagination;
        },
        _filter(state) {
            return state.filter;
        }
    },
    mutations: {
        nullProducts(state) {
            state.products = [];
        },
        setProducts(state, data) {
            state.products = data.data;
            delete data.data;
            state.pagination = data;
        },
        addPreOrder(state, preOrder) {
            state.products.forEach(product => {
                if (product.id === preOrder.az_store_product_id) product.pre_orders.push(preOrder);
            })
        }
    },
    actions: {
        getFilter(context) {
            context.commit('nullProducts');
            return axios.post('/amazon/store/product/filter', context.state.filter)
                .then(res => {
                    context.commit('setProducts', res.data.content)
                    return true;
                })
        },
        addPreOrder(context, payload) {
            return axios.post('preorder', payload)
                .then(res => {
                    if (res.data.status == 'success') context.commit('addPreOrder', res.data.content)
                    return res.data;
                })
        }
    },
}
