import Vue from 'vue'
import VueRouter from 'vue-router'

import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import home from "@/router/home";
import product from "@/router/product";
import order from "@/router/order";
import question from "@/router/question";
import supplier from "@/router/supplier";
import marketplace from "@/router/marketplace";
import hepsiburada from "@/router/hepsiburada";
import trendyol from "@/router/trendyol";
import amazon from "@/router/amazon";
import invoice from "@/router/invoice";
import stock from "@/router/stock";
import stockV2 from "@/router/stockV2";
import agreement from "@/router/agreement";
import other from "@/router/other";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    routes: [
        ...home,
        ...product,
        ...order,
        ...question,
        ...supplier,
        ...marketplace,
        ...hepsiburada,
        ...trendyol,
        ...amazon,
        ...invoice,
        ...stock,
        ...stockV2,
        ...agreement,
        ...other,
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({name: 'auth-login'})

        // If logged in => not authorized
        return next({name: 'not-authorized'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
