export default [
    {
        path: '/supplier',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'supplier',
                component: () => import('@/views/supplier/SupplierHome.vue'),
                meta: {}
            },
            {
                path: 'product',
                name: 'supplier-product',
                component: () => import('@/views/supplier-product/SupplierProductHome.vue'),
                meta: {}
            }
        ]
    }
]
