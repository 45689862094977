import axios from '@/libs/axios'
import log from 'echarts/src/scale/Log'

export default {
    namespaced: true,
    state: {
        isOrderProductList: false,
        orders: [],
        orderStatusCounts: [],
        timelineOrderId: '',
        timelineOrderProductId: '',
        orderProductStatisticsProductId: '',
        selectedOrders: [],
        selectedOrder: '',
        filterBasket: '',
        autoAddBasket: 0,
        autoSelectOrder: null,
        filterBarcode: '',
        filterName: '',
        selectedOrdersVariant: 'success',
        pagination: null,
        search: {
            id: '',
            order_number: '',
            package_number: '',
            is_commercial: false,
            is_not_invoiced: false,
            micro: false,
            product: {
                id: '',
                barcode: '',
                product_name: '',
                is_not_tax: false,
            },
            tracking: {
                tracking_number: '',
                companies: [],
            },
            brands: [],
            isBasket: false,
            exclusion: false,
            accountings: false,
            profit: false,
            isSingleMulti: 'all',
            isStocks: false,
            isSingle: true,
            isMulti: true,
            channels: ['MFN'],
            isAfn: false,
            isAte: false,
            isFollow: false,
            orderStatus: [],
            stores: [],
            suppliers: [],
            publishers: [],
            startDate: '',
            endDate: '',
            dateType: 'created_at',
            orderBy: 'created_at',
            sorting: 'DESC',
            limit: 10,
            page: 1
        },
        isMpImage: true,

    },
    getters: {
        _timelineOrderId(state) {
            return state.timelineOrderId
        },
        _timelineOrderProductId(state) {
            return state.timelineOrderProductId
        },
        _orderProductStatisticsProductId(state) {
            return state.orderProductStatisticsProductId
        },
        _isOrderProductList(state) {
            return state.isOrderProductList
        },
        _orders(state) {
            if (state.autoSelectOrder) return [state.autoSelectOrder] //otomatik seçilmiş sipariş varsa onu göstermeye devam edelim

            if (state.search.isBasket && state.filterBasket) {
                return state.orders.filter(order => {
                    if (order.invoice_basket) {
                        if (order.invoice_basket.basket_name == state.filterBasket) return true
                        else return false
                    } else return false
                })
            }

            if ((state.filterBarcode || state.filterName) && !state.autoAddBasket) {
                return state.orders.filter(order => {
                    let products = order.products.filter(product => {
                        if (product.product_barcode === state.filterBarcode) return true
                        else if (product.product_name.toLowerCase().indexOf(state.filterName.toLowerCase()) > -1) return true;
                        return false
                    })

                    if (products.length > 0) return true
                    return false
                })
            } else if (state.filterBarcode && state.autoAddBasket) {
                let order = state.orders.find(order => { //siparişleri dönelim kontrol edelim
                    if ([1, 2, 3, 13].includes(order.order_status_id) && !order.invoice_basket) {
                        if (order.products.length == 1) {

                            //sipariş ürünü tek kalemse buradan devam edelim
                            //sipariş statusu ve barkod uygunsa devam edelim
                            if (![9, 27].includes(order.products[0].supplier_id) || state.search.orderStatus.includes(13)) {
                                if (order.products[0].product_barcode == state.filterBarcode) {
                                    return true
                                } else {
                                    if (order.products[0].product) {
                                        let barcodes = order.products[0].product.barcodes.find(barcodeItem => {
                                            if (barcodeItem.barcode == state.filterBarcode) return true
                                        })
                                        if (barcodes) return true
                                    }
                                }
                            }

                        } else { //sipariş kalemi çok ise buradan devam edelim
                            let product = order.products.find(product => {
                                if (!product.come && (![9, 27].includes(product.supplier_id) || state.search.orderStatus.includes(13))) {
                                    if (product.product_barcode == state.filterBarcode) {
                                        return true
                                    } else {
                                        if (product.product) {
                                            let barcodes = product.product.barcodes.find(barcodeItem => {
                                                if (barcodeItem.barcode == state.filterBarcode) return true
                                            })
                                            if (barcodes) return true
                                        }
                                    }

                                }

                            })
                            if (product) return true
                        }
                    }
                })
                if (order) {
                    return [order]
                }
                return []
            }
            return state.orders
        },
        _orderProducts(state) {
            let orderProducts = []
            state.orders.forEach(order => {
                order.products.forEach(product => {
                    let status = true
                    orderProducts.forEach(orderProduct => {
                        if (product.product_id == orderProduct.product_id) {
                            status = false
                            orderProduct.t_commission += product.commission
                            orderProduct.t_quantity += product.quantity
                            orderProduct.t_price += product.total_sales_price
                            if (product.stock_card) {
                                orderProduct.stock_cards.forEach(stockCard => {
                                    if (stockCard.shelve_name == product.stock_card.shelve_name) {
                                        stockCard.quantity += product.stock_card.quantity
                                    }
                                })
                            }
                        }
                    })
                    if (status) {
                        let stock_cards = []
                        if (product.stock_card) {
                            stock_cards = [{
                                shelve_name: product.stock_card.shelve_name,
                                quantity: product.stock_card.quantity,
                            }]
                        }
                        orderProducts.push({
                            product_id: product.product_id,
                            images: product.images,
                            product_name: product.product_name,
                            product_barcode: product.product_barcode,
                            t_commission: product.commission,
                            t_quantity: product.quantity,
                            t_price: product.total_sales_price,
                            stock_cards,
                            product: product
                        })
                    }
                })
            })
            return orderProducts
        },
        _selectedOrders(state) {
            return state.selectedOrders
        },
        _selectedOrder(state) {
            return state.selectedOrder
        },
        _search(state) {
            return state.search
        },
        _pagination(state) {
            return state.pagination
        },
        _filterBasket(state) {
            return state.filterBasket
        },
        _filterBarcode(state) {
            return state.filterBarcode
        },
        _getAllProductId(state) {
            let productsId = []
            state.orders.forEach(order => {
                order.products.forEach(product => {
                    if (!productsId.includes(product.product_id)) {
                        productsId.push(product.product_id)
                    }
                })
            })
            return productsId
        },
        _orderStatusCounts(state) {
            return state.orderStatusCounts
        }
    },
    mutations: {
        setTimelineOrderId(state, id) {
            state.timelineOrderId = id
        },
        setTimelineOrderProductId(state, id) {
            state.timelineOrderProductId = id
        },
        setOrderProductList(state, status) {
            state.isOrderProductList = status
        },
        setAutoAddBasket(state, autoAddBasket) {
            state.autoAddBasket = autoAddBasket
        },
        setFilterBasket(state, basketName) {
            state.filterBasket = basketName
        },
        setFilterName(state, name) {
            state.filterName = name
        },
        setFilterBarcode(state, barcode) {
            state.filterBarcode = barcode
        },
        setSelectedOrder(state, order) {
            state.selectedOrder = order
        },
        setSearchNull(state) {
            state.search = {
                id: '',
                order_number: '',
                package_number: '',
                is_commercial: false,
                is_not_invoiced: false,
                product: {
                    id: '',
                    barcode: '',
                    product_name: '',
                    is_not_tax: false,
                },
                tracking: {
                    tracking_number: '',
                    companies: [],
                },
                brands: [],
                isBasket: false,
                isStocks: false,
                channels: ['MFN'],
                isAfn: false,
                isAte: false,
                isFollow: false,
                orderStatus: [],
                stores: [],
                suppliers: [],
                publishers: [],
                startDate: '',
                endDate: '',
                dateType: 'created_at',
                orderBy: 'created_at',
                sorting: 'DESC',
                limit: 10,
                page: 1
            }
        },
        setOrders(state, orders) {
            state.orders = orders
        },
        setPagination(state, data) {
            delete data.data
            state.pagination = data
        },
        setOrder(state, data) {
            state.orders.forEach(order => {
                if (order.id == data.id) {
                    if (data.order) {
                        if (data.order.order_status_id) order.order_status_id = data.order.order_status_id
                    }
                    if (data.status) order.status = data.status
                    if (data.invoice_basket) {
                        if (data.invoice_basket == 'null') {
                            order.invoice_basket = null
                        } else {
                            order.invoice_basket = data.invoice_basket
                        }
                    }
                }
            })
        },
        setOrderProduct(state, data) {
            state.orders.forEach(order => {
                order.products.forEach(product => {
                    if (product.id == data.id) {
                        if (data.supplier_id) {
                            product.supplier_id = data.supplier_id
                        }
                        if (data.supplier) {
                            product.supplier = data.supplier
                            product.supplier_id = data.product.supplier_id
                        }
                        if (data.come) {
                            product.come = data.come
                        }
                        if (data.messages) {
                            product.messages = data.messages
                        }
                    }
                })
            })
        },
        setOrderProductStock(state, data) {

        },
        deleteBrand(state, key) {
            state.search.brands.splice(key, 1)
        },
        addExclusion(state, data) {
            state.orders.forEach(order => {
                if (order.id == data.order_id) {
                    order.exclusion = data
                }
            })
        },
        delExclusion(state, orderId) {
            state.orders.forEach(order => {
                if (order.id == orderId) {
                    order.exclusion = null
                }
            })
        },
        setOrderStatusCounts(state, counts) {
            state.orderStatusCounts = counts
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/order/all', context.state.search)
                .then(res => {
                    context.commit('setOrders', res.data.data)
                    context.commit('setPagination', res.data)
                    return {
                        title: 'Siparişler geldi',
                        variant: 'success',
                        icon: 'ArrowUpCircleIcon',
                    }
                })
                .catch(() => {
                    alert('sipariş çekme hatası')
                    return {
                        title: 'Sipariş çekme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        getAllStock(context) {
            console.log(context.getters._getAllProductId.length)
            if (context.getters._getAllProductId.length < 1) {
                return {
                    title: 'Stok çekilecek ürün yok',
                    variant: 'warning',
                    icon: 'XCircleIcon',
                }
            }
            return axios.post('/order/product/stock', {ids: context.getters._getAllProductId})
                .then(res => {
                    context.commit('setOrderProductStock', res.data.data)
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowUpCircleIcon',
                    }
                })
                .catch(() => {
                    alert('Sipariş ürün stok çekme hatası')
                    return {
                        title: 'Sipariş ürün stok çekme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        updateOrder(context, data) {
            return axios.put('/order/' + data.id, data.order)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setOrder', data)
                        return {
                            title: 'Sipariş bilgisi düzenlendi',
                            variant: 'success',
                            icon: 'ArrowUpCircleIcon',
                        }
                    } else {
                        return {
                            title: 'Sipariş bilgisi düzenleme başarısız',
                            variant: 'warning',
                            icon: 'ArrowDownCircleIcon',
                        }
                    }
                })
                .catch(() => {
                    alert('sipariş bilgisi değiştirme hatası')
                    return {
                        title: 'Sipariş bilgisi düzenleme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        orderAddBasket(context, data) {
            return axios.post('order/basket', data.invoice_basket)
                .then(res => {
                    data.status = res.data.status
                    data.order = res.data.order
                    data.invoice_basket = res.data.invoice_basket
                    context.commit('setOrder', data)
                    return {
                        title: res.data.message,
                        variant: res.data.status1,
                        icon: 'ArrowUpCircleIcon',
                    }
                })
                .catch(() => {
                    alert('Sipariş sepete atma hatası')
                    return {
                        title: 'Sipariş sepete atma hatası',
                        variant: 'danger',
                        icon: 'ArrowDownCircleIcon',
                    }
                })
        },
        orderDelBasket(context, data) {
            return axios.delete('/order/basket/del/' + data.id)
                .then(res => {
                    if (res.data.status) {
                        data.invoice_basket = null
                    }
                    data.invoice_basket = 'null'
                    context.commit('setOrder', data)
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowUpCircleIcon',
                    }
                })
                .catch(() => {
                    alert('Sepeten silme başarısız')
                    return {
                        title: 'Sepeten silme başarısız',
                        variant: 'danger',
                        icon: 'ArrowDownCircleIcon',
                    }
                })
        },
        orderAddBasketBack(context, data) {
            return axios.delete('/order/basket/' + data.id)
                .then(res => {
                    data.status = res.data.status
                    data.order = res.data.order
                    data.invoice_basket = 'null'
                    context.commit('setOrder', data)

                    return {
                        title: res.data.message,
                        variant: res.data.status1,
                        icon: 'ArrowDownCircleIcon',
                    }

                })
                .catch(() => {
                    alert('Sepete ekle geri alma başarısız')
                    return {
                        title: 'Sepete ekle geri alma başarısız',
                        variant: 'danger',
                        icon: 'ArrowDownCircleIcon',
                    }
                })
        },
        updateOrderProductPrice(context, data) {
            return axios.put('/order/product/price/' + data.id, data.price)
                .then(res => {
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowUpCircleIcon',
                    }
                }).catch(() => {
                    return {
                        title: 'Ürün bilgisi düzenleme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        updateOrderProduct(context, data) {
            return axios.put('/order/product/' + data.id, data.product)
                .then(res => {
                    if (res.data.order) {
                        context.commit('setOrder', {
                            id: res.data.order.id,
                            order: {
                                order_status_id: res.data.order.order_status_id
                            },
                            status: res.data.order.status,
                        })
                    }
                    if (res.data.status == 'success') {
                        context.commit('setOrderProduct', data)
                        context.commit('setOrderProduct', {
                            id: data.id,
                            supplier_id: data.product.supplier_id
                        })
                    }
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowUpCircleIcon',
                        order: res.data.order
                    }
                })
                .catch(() => {
                    alert('Ürün bilgisi değiştirme hatası')
                    return {
                        title: 'Ürün bilgisi düzenleme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        crateOrderProductMessage(context, data) {
            return axios.post('order/product/message', data.product)
                .then(res => {
                    data.messages = res.data.messages
                    context.commit('setOrderProduct', data)
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowDownCircleIcon'
                    }
                })
                .catch(() => {
                    return {
                        title: 'Ürün mesaj ekleme hatası',
                        variant: 'danger',
                        icon: 'ArrowDownCircleIcon'
                    }
                })
        },
        createOrderProductCome(context, data) {
            return axios.post('/order/product/come', data.product)
                .then(res => {
                    data.come = res.data.come
                    context.commit('setOrderProduct', data)
                    if (res.data.status == 'success') {
                        return {
                            title: 'Ürün başarı ile  geldi yapıldı',
                            variant: 'info',
                            icon: 'ArrowUpCircleIcon',
                            message: res.data.message,
                            come: res.data.come
                        }
                    } else {
                        return {
                            title: 'Ürün daha önce geldi yapılmış',
                            variant: 'warning',
                            icon: 'ArrowDownCircleIcon',
                            message: res.data.message,
                            come: null
                        }
                    }
                })
                .catch(() => {
                    alert('Ürün geldi yapma hatası')
                    return {
                        title: 'Ürün geldi yapma hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                        message: '',
                    }
                })
        },
        delOrderProductCome(context, data) {
            return axios.delete('/order/product/come/' + data.id)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setOrderProduct', data)
                        return {
                            title: 'Ürün geldi silme başarı ile yapıldı',
                            variant: 'success',
                            icon: 'ArrowUpCircleIcon',
                            message: res.data.message,
                        }
                    } else {
                        return {
                            title: 'Ürün geldi silme başarısız oldu',
                            variant: 'warning',
                            icon: 'ArrowDownCircleIcon',
                            message: res.data.message,
                        }
                    }

                })
                .catch(() => {
                    alert('Ürün geldi silme hatası')
                    return {
                        title: 'Ürün geldi silme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                        message: '',
                    }
                })
        },
        delToExclusion(context, orderId) {
            axios.delete('/exclusion/' + orderId)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('delExclusion', orderId)
                    }
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowDownCircleIcon',
                    }
                })
                .catch(() => {
                    return {
                        title: 'Başarısız',
                        variant: 'danger',
                        icon: 'ArrowDownCircleIcon',
                        message: 'Hariç listesi silme başarısız',
                    }
                })
        },
        addToExclusion(context, orderId) {
            axios.post('/exclusion', {order_id: orderId})
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('addExclusion', res.data.data)
                    }
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowDownCircleIcon',
                    }
                })
                .catch(() => {
                    return {
                        title: 'Başarısız',
                        variant: 'danger',
                        icon: 'ArrowDownCircleIcon',
                        message: 'Hariç listesine ekleme başarısız',
                    }
                })
        },
        addNewOrder(context, data) {
            return axios.post('/order_fba', data)
                .then(res => {
                    return res.data
                })
                .catch(() => {
                    return {
                        status: 'danger',
                        message: 'Başarısız'
                    }
                })
        },
        getOrderStatusCounts(context) {
            axios.get('order/status/count')
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setOrderStatusCounts', res.data.data)
                    }
                })
                .catch(() => {
                    console.log('Order Status Count Error')
                })
        },
        setOrderProductAddBasket(context, data) {
            return axios.put('/order/product/addbasket/' + data.id, data)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setOrderProduct', {
                            id: data.id,
                            supplier_id: res.data.supplier_id
                        })
                    }
                    return res.data;
                })
        },
    },
}
