import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        labels: [],
    },
    getters: {
        _labels(state) {
            return state.labels
        }
    },
    mutations: {
        setLabels(state, labels) {
            state.labels = labels
        },
    },
    actions: {
        getAll(context, data) {
            return axios.post('/common/label', data)
                .then(res => {
                    context.commit('setLabels', res.data)
                    return true;
                })
                .catch(() => {
                    return false;
                })
        }
    }
}
