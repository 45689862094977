import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        supplierProducts: [],
        pagination: null,
        search: {
            stock: {
                condition: "",
                quantityX: null,
                quantityY: null,
            },
            category_ids: [],
            publisher_ids: [],
            author_ids: [],
            supplier_ids: [],
            brands: [],
            name: "",
            barcode: "",
            status: "",
            limit: 25,
            page: 1,
            selectedFilter: [],
            selectedOptions: [],
            isSupplierProductChange: 'all',
            product: {
                product_id: "",
                barcode: "",
                name: ""
            }
        },
        optionsView: [
            {text: 'Barkodsuzlar', value: 'barcodeNull'},
        ],
        filterView: [
            {text: 'Ürünler', value: 'product'},
            {text: 'Kitap Detay', value: 'bookDetail'},
            {text: 'Kategoriler', value: 'categories'},
            {text: 'Yayımevleri', value: 'publishers'},
            {text: 'Yazarlar', value: 'authors'},
        ]
    },
    getters: {
        _supplierProducts(state) {
            return state.supplierProducts;
        },
        _pagination(state) {
            return state.pagination
        },
        _search(state) {
            return state.search;
        },
        _filterView(state) {
            return state.filterView;
        }

    },
    mutations: {
        setSupplierProducts(state, data) {
            state.supplierProducts = data.data;
            delete data.data;
            state.pagination = data;
        },
        editSupplierProducts(state, product) {
            let foundIndex = state.supplierProducts.findIndex(item => parseInt(item.id) == parseInt(product.id));
            state.supplierProducts.splice(foundIndex, 1, product);
        },
        deleteBrand(state, key) {
            state.search.brands.splice(key, 1);
        },
        clearSearch(state) {
            state.search = {
                stock: {
                    condition: "",
                    quantityX: null,
                    quantityY: null,
                },
                category_ids: [],
                publisher_ids: [],
                author_ids: [],
                supplier_ids: [],
                brands: [],
                name: "",
                barcode: "",
                status: "",
                limit: 25,
                page: 1,
                selectedFilter: [],
                selectedOptions: [],
                isSupplierProductChange: 'all',
                product: {
                    product_id: "",
                    barcode: "",
                    name: ""
                }
            }
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/supplier-product/all', context.state.search)
                .then(res => {
                    context.commit('setSupplierProducts', res.data)
                    return res.data;
                })
                .catch(error => {
                        alert('Tedarikçi Ürün Çekme Hatası')
                        return error
                    }
                )
        },
        edit(context, data) {
            return axios.put('/supplier-product/' + data.id, data)
                .then(res => {
                    context.commit('editSupplierProducts', res.data)
                    return res.data;
                })
                .catch(() => {
                    alert('Ürün düzenleme hatası');
                    return [];
                })
        }
    }
}
