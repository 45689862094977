import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        campaigns: [],
        search: {},
    },
    getters: {
        _campaigns(state) {
            return state.campaigns;
        }
    },
    mutations: {
        setCampaigns(state, campaigns) {
            state.campaigns = campaigns
        }
    },
    actions: {
        getCampaigns(context) {
            axios.post('/hepsiburada/campaign/all', context.state.search)
                .then(res => {
                    context.commit('setCampaigns', res.data)
                })
                .catch(() => {
                    alert('HB Kampanya listesi çekme hatası')
                })
        }
    }
}
