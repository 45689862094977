import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        baskets: [],
        selectBasket: "",
        selected: "",
    },
    getters: {
        _baskets(state) {
            return state.baskets;
        },
        _selected(state) {
            return state.selected;
        },
    },
    mutations: {
        setBaskets(state, baskets) {
            state.baskets = baskets
        },
        setSelected(state, selected) {
            state.selected = selected
        }
    },
    actions: {
        getAll(context, status) {
            if (!status) status = 'not'
            return axios.get('/order/basket/' + status)
                .then(res => {
                    context.commit('setBaskets', res.data.baskets)
                })
                .catch(() => {
                    alert('Sipariş sepete çekme hatası')
                    return {
                        title: 'Sipariş sepete çekme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    };
                })
        }
    },
}
