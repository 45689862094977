import Vue from 'vue'

// axios
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'

const axiosIns = axios.create({
    baseURL: 'https://api.kitapfirsatlari.com/api/',
    //headers: {'X-Custom-Header': 'foobar'}
})
// You can add your headers here
// ================================
// baseURL: 'https://some-domain.com/api/',
// timeout: 1000,

// İstek için yol kesici ekle
axiosIns.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status == 401) {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userData')
        location.reload()
    } else {
        console.log(error.response.status + ' Hatası verdi')
    }
    return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
