import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        products: [],
        pagination: null,
        search: {
            status: [],
            product: {
                id: "",
                barcode: "",
                name: "",
            },
            limit: 15,
            page: 1
        }
    },
    getters: {
        _products(state) {
            return state.products
        },
        _search(state) {
            return state.search;
        },
        _pagination(state) {
            return state.pagination
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
        delProduct(state, id) {
            let index = state.products.findIndex(item => item.id == id)
            state.products.slice(index, 1);
        },
        setPagination(state, data) {
            delete data.data;
            state.pagination = data;
        },
        setNewDiscount(state, discount) {
            state.products.forEach(stock => {
                stock.discount = discount
                stock.price = stock.old_market_price - (stock.old_market_price * stock.discount / 100)
            })
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/stock/all', context.state.search)
                .then(res => {
                    context.commit('setProducts', res.data.data)
                    context.commit('setPagination', res.data)
                    return true;
                })
                .catch(() => {
                    alert('stok seçkme hatası')
                    return true;
                })
        },
        update(context, data) {
            return axios.put('/stock/edit/' + data.id, data)
                .then(res => {
                    context.commit('delProduct', data.id)
                    return true;
                })
                .catch(() => {
                    alert('stok güncelleme hatası')
                    return true;
                })
        },
        addnew(context, data) {
            return axios.post('stock/card', data)
                .then(res => {
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowUpCircleIcon',
                    }
                })
                .catch(() => {
                    return {
                        title: 'Stock düzeltme hatası',
                        variant: 'danger',
                        icon: 'ArrowUpCircleIcon',
                    }
                })
        },
        delete(context, data) {
            return axios.post('stock/delete', data)
                .then(res => {
                    context.rootState.stockV2.selected = [];
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'ArrowUpCircleIcon',
                    };
                })
                .catch(() => {
                    return {
                        title: 'Stock/iade silme hatası',
                        variant: 'danger',
                        icon: 'ArrowUpCircleIcon',
                    }
                })
        }
    }
}
