import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {
        _items(state) {
            return state.items;
        },
        _statistics(state) {
            let statistics = {
                sumItem: {
                    name: 'Toplam',
                    count: 0,
                    okCount: 0,
                },
                sumTex: {
                    name: 'Tex Kargo',
                    count: 0,
                    okCount: 0
                },
                sumSurat: {
                    name: 'Sürat Kargo',
                    count: 0,
                    okCount: 0
                },
                sumHbj: {
                    name: 'HbJet Kargo',
                    count: 0,
                    okCount: 0
                },
            }
            state.items.forEach(item => {
                statistics.sumItem.count++;
                if (item.order.tracking.cargo_company_id == 5) {
                    statistics.sumTex.count++;
                } else if (item.order.tracking.cargo_company_id == 2) {
                    statistics.sumSurat.count++;
                } else if (item.order.tracking.cargo_company_id == 4) {
                    statistics.sumHbj.count++;
                }

                if ([6, 7].includes(item.order.order_status_id)) {
                    statistics.sumItem.okCount++;
                    if (item.order.tracking.cargo_company_id == 5) {
                        statistics.sumTex.okCount++;
                    } else if (item.order.tracking.cargo_company_id == 2) {
                        statistics.sumSurat.okCount++;
                    } else if (item.order.tracking.cargo_company_id == 4) {
                        statistics.sumHbj.okCount++;
                    }
                }

            })
            return statistics;
        }
    },
    mutations: {
        setItems(state, items) {
            state.items = items;
        },
        addItem(state, item) {
            state.items.push(item)
        }
    },
    actions: {
        getAll(context) {
            return axios.get('/agreement/detail')
                .then(res => {
                    context.commit('setItems', res.data.items);
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                    };
                })
                .catch(() => {
                    return {
                        title: 'Mutabakat takip no çekme hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    };
                })
        },
        setTrackingNumber(context, data) {
            return axios.post('/agreement/detail', data)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('addItem', res.data.item);
                    }
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                    };
                })
                .catch(() => {
                    alert('Mutabakat takip no giriş hatası')
                    return {
                        title: 'Mutabakat takip no giriş hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    };
                })
        },
        setAgreement(context, data) {
            return axios.post('/agreement', data)
                .then(res => {
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                    };
                })
                .catch(() => {
                    return {
                        title: 'Mutabakat kayıt hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    };
                })
        }
    }
}
