export default [
    {
        path: '/hepsiburada',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'hepsiburada-product',
                component: () => import('@/views/hepsiburada/product/ProductHome'),
                meta: {
                    pageTitle: 'Ürün Listesi',
                    breadcrumb: [
                        {
                            text: 'Hepsiburada',
                            active: false,
                        },
                        {
                            text: 'Ürün Listesi',
                            active: true,
                        },
                    ]
                }
            },
            {
                path: 'campaign',
                name: 'hepsiburada-campaign',
                component: () => import('@/views/hepsiburada/campaign/CampaignHome'),
                meta: {
                    pageTitle: 'Kampanyalar',
                    breadcrumb: [
                        {
                            text: 'Hepsiburada',
                            active: false,
                        },
                        {
                            text: 'Kampanyalar',
                            active: true,
                        },
                    ]
                }
            },
            {
                path: 'eslestirme',
                name: 'hepsiburada-eslestirme',
                component: () => import('@/views/hepsiburada/matching/MatchingHome'),
                meta: {}
            }
        ]
    }
]
