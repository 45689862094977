export default [
    {
        path: '/store',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'store',
                component: () => import('@/views/store/StoreHome.vue'),
                meta: {},
            },
            {
                path: 'product',
                name: 'store-product',
                component: () => import('@/views/store-product/StoreProductHome.vue'),
                meta: {},
            },
            {
                path: 'accounting',
                name: 'store-accounting',
                component: () => import('@/views/store/accounting/AccountingHome'),
                meta: {},
            },
        ]
    }
]
