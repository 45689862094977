export default [
    {
        path: '/products',
        name: 'products',
        component: () => import('@/views/products/Home.vue'),
        meta: {},
        children: [],
    },
    {
        path: '/product',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'product',
                component: () => import('@/views/product/ProductHome.vue'),
                meta: {},
            },

            {
                path: '/product2',
                name: 'product2',
                component: () => import('@/views/product/product/Home.vue'),
                meta: {},
            },
            {
                path: 'takim',
                name: 'takim',
                component: () => import('@/views/product/bundle/ProductBundleHome'),
                meta: {},
            },
            {
                path: 'marka',
                name: 'marka',
                component: () => import('@/views/product/brand/ProductBrandHome'),
                meta: {},
            },
            {
                path: 'category',
                name: 'category',
                component: () => import('@/views/product-category/CategoryHome.vue'),
                meta: {},
            },
            {
                path: 'publisher',
                name: 'publisher',
                component: () => import('@/views/product-publisher/PublisherHome.vue'),
                meta: {},
            },
            {
                path: 'author',
                name: 'author',
                component: () => import('@/views/product-publisher/PublisherHome.vue'),
                meta: {},
            }
        ]
    },
]
