import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        questions2: [],
        pagination: null,
        search: {
            page: 1,
            stores: [],
            statuses: ['WAITING_FOR_ANSWER', 'WAITING_FOR_APPROVE'],
        },
        statuses: [
            {key: 'WAITING_FOR_ANSWER', name: 'Cevap Bekliyor'},
            {key: 'WAITING_FOR_APPROVE', name: 'Onay Bekliyor'},
            {key: 'ANSWERED', name: 'Cevaplanmış'},
            {key: 'REPORTED', name: 'Rapor Edilmiş'},
            {key: 'REJECTED', name: 'Ret Edilmiş'},
        ]
    },
    getters: {
        _questions2(state) {
            return state.questions2
        },
        _questions2WaitingLengt(state) {
            return state.questions2.filter(item => ['WAITING_FOR_ANSWER', 'WAITING_FOR_APPROVE'].includes(item.status)).length
        },
        _pagination(state) {
            return state.pagination
        },
        _search(state) {
            return state.search
        },
        _statuses(state) {
            return state.statuses
        }
    },
    mutations: {
        setQuestions2(state, data) {
            state.questions2 = data.data
            delete data.data
            state.pagination = data
        },
        setQuestion(state, question) {
            let key = state.questions2.findIndex(item => item.id === question.id);
            if (key > -1) {
                state.questions2.splice(key, 1, question)
            }
        }
    },
    actions: {
        getAll2(context, payload) {
            return axios.get('/question', {params: context.state.search})
                .then(res => {
                    if (res.data.status === 'success') {
                        context.commit('setQuestions2', res.data.content)
                    }
                })
        },
        answer(context, payload) {
            return axios.post(`question/${payload.id}/answer`, payload)
                .then(res => {
                    context.commit('setQuestion', res.data.content)
                })
        }
    }
}
