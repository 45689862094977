import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        status: [],
    },
    getters: {
        _matchStatus(state) {
            return state.status
        }
    },
    mutations: {
        setStatus(state, status) {
            state.status = status
        },

    },
    actions: {
        getAll(context) {
            return axios.get('/status/match')
                .then(res => {
                    context.commit('setStatus', res.data)
                })
                .catch(() => {
                    alert('Eşleşme statüsü çekme hatası')
                })
        }
    }
}
