import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        confirmList: [],
    },
    getters: {
        _confirmList(state) {
            return state.confirmList;
        }
    },
    mutations: {
        setConfirmList(state, payload) {
            state.confirmList = payload
        }
    },
    actions: {
        getConfirmList: function (vueContext) {
            return axios.get(`/v2/stock/stocktaking/confirm`)
                .then(res => {
                    if (res.data.status === 'success') {
                        vueContext.commit('setConfirmList', res.data.content)
                    }
                    return res.data;
                })
            console.log(vueContext)
        }
    }
}