export default [
    {
        path: '/trendyol',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'trendyol',
                component: () => import('@/views/trendyol/product/ProductHome'),
                meta: {}
            },
            {
                path: 'urunacma',
                name: 'trendyol-urunacma',
                component: () => import('@/views/trendyol/create-product/Home.vue'),
                meta: {}
            },
            {
                path: 'eslestirme',
                name: 'trendyol-eslestirme',
                component: () => import('@/views/trendyol/matching/MatchingHome'),
                meta: {}
            }
        ]
    }
]
