import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        products: [],
        pagination: {},
        filter: {
            limit: 5,
            page: 1,
            order: 'id',
            sorting: "DESC"
        },
    },
    getters: {},
    mutations: {
        setProducts(state, products) {
            state.products = products;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        }
    },
    actions: {
        getFilter(context) {
            return axios.post('trendyol/store-product/filter', context.state.filter)
                .then(res => {
                    if (res.data.status === 'success') {
                        context.commit('setProducts', res.data.content.data)
                        delete res.data.content.data;
                        context.commit('setPagination', res.data.content);
                        return true
                    }
                    return false;
                })
                .catch(() => {
                    return false
                })
        }
    }
}
