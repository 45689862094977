import axios from '@/libs/axios'
import log from 'echarts/src/scale/Log'

export default {
    namespaced: true,
    state: {
        currentList: [],
    },
    getters: {
        _currentList(state) {
            return state.currentList;
        }
    },
    mutations: {
        setCurrentList(state, currentList) {
            state.currentList = currentList
        }
    },
    actions: {
        getCompanyList(context) {
            axios.get('/current')
                .then(res => {
                    context.commit('setCurrentList', res.data.content)
                })
                .catch(() => {
                    alert('cari firması çekme hatası')
                })
        }
    },
}
