import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        statistics: [],
        others: [],
        invoiceList: [],
        searchData: {
            in_out: 1,
            limit: 25,
        },
        pagination: {},
        fields: [
            {key: 'id', label: 'No'},
            {key: "company.short_name", label: "Firma"},
            {key: "order_id", label: "Sipariş No"},
            {key: "invoice_detail", label: "Fatura Tarihi"},
            {key: "customer_detail", label: "Müşteri Detay"},
            {key: "document_currency_code", label: "Birimi"},
            {key: "payable_amount", label: "Tutar"},
            {key: "tax_total", label: "KDV"},
            {key: "tax_exclusive_amount", label: "Matrah"},
            {key: "archived", label: "Arşiv"},
            {key: "invoice_type", label: "Fatura Tipi"},
            {key: "status_detail", label: "Durum"},
            {key: "actions", label: "İşlemler"},
        ],
        status: [
            {statu: 0, enum: 'Draft', value: 'Taslak', variant: 'warning'},
            {statu: 10, enum: 'Canceled', value: 'İptal Edildi', variant: 'danger'},
            {statu: 100, enum: 'Queued', value: 'Kuyrukta', variant: 'warning'},
            {statu: 200, enum: 'Processing', value: 'İşlemde', variant: 'warning'},
            {statu: 300, enum: 'SentToGib', value: 'GİB’e gönderildi', variant: 'warning'},
            {statu: 1000, enum: 'Approved', value: 'Onaylandı', variant: 'success'},
            {statu: 1100, enum: 'WaitingForApprovement', value: 'Onay Bekliyor', variant: 'warning'},
            {statu: 1150, enum: 'SystemError', value: 'SCHEMATRON KONTROL SONUCU HATALI', variant: 'warning'},
            {statu: 1195, enum: 'SystemError', value: 'SISTEM HATASI', variant: 'warning'},
            {statu: 1200, enum: 'Declined', value: 'Reddedildi', variant: 'danger'},
            {statu: 1300, enum: 'Return', value: 'İade Edildi', variant: 'danger'},
            {statu: 1400, enum: 'eArchiveCanceled', value: 'e-Arşiv İptal', variant: 'danger'},
            {statu: 2000, enum: 'Error', value: 'Hata', variant: 'danger'},
        ],
        scenario: [
            {name: 'Bireysel', value: 1},
            {name: 'Kurumsal', value: 0},
        ],
        invoice_type: [
            {name: 'Ticari', value: 1},
            {name: 'Temel', value: 0},
        ],
        invoice_tip_type: [
            {name: 'Satış', value: 0},
            {name: 'İade', value: 1},
            {name: 'İstisna', value: 3},
        ]
    },
    getters: {
        _statistics(state) {
            return state.statistics;
        },
        _others(state) {
            return state.others
        },
        _invoiceList(state) {
            return state.invoiceList
        },
        _invoiceListCount(state) {
            return state.invoiceList.length
        },
        _searchData(state) {
            return state.searchData
        },
        _pagination(state) {
            return state.pagination
        },
        _fields(state) {
            return state.fields
        },
        _status(state) {
            return state.status;
        },
        _scenario(state) {
            return state.scenario
        },
        _invoice_type(state) {
            return state.invoice_type
        },
        _invoice_tip_type(state) {
            return state.invoice_tip_type
        },
    },
    mutations: {
        setInvoiceList(state, data) {
            state.invoiceList = data.data
            delete data.data
            state.pagination = data
        },
        setStatistics(state, statistics) {
            state.statistics = statistics
        },
    },
    actions: {
        getInvoiceList(context) {
            context.commit('setInvoiceList', {data: []});
            axios.post('/invoice', context.state.searchData)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setInvoiceList', res.data.content)
                    }
                })
        },
        updateInvoice(context, data) {
            return axios.put('invoice/' + data.document_id, data)
                .then(res => {
                    return res.data;
                })
        },
        showInvoice(context, data) {
            return axios.get('/invoice/show/' + data.documentId + '/' + data.action)
                .then(res => {
                    return res.data;
                })
        },
        getInvoiceUyum(context, data) {
            axios.get('/invoice/getInvoiceUyum/' + data.companyId + '/' + data.invoiceType);
        },
        getStatistics(context) {
            axios.get('/invoice/statistics')
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setStatistics', res.data.content);
                    }
                })
        }
    },
}
