export default [
    {
        path: '/amazon',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'amazon-urun',
                component: () => import('@/views/amazon/matching/MatchingHome'),
                meta: {}
            },
            {
                path: 'store-product',
                name: 'amazon-store-product',
                component: () => import('@/views/amazon/store-product/StoreProductHome.vue'),
                meta: {}
            },
            {
                path: 'pre-order',
                name: 'amazon-pre-order',
                component: () => import('@/views/amazon/pre-order/PreOrderHome.vue'),
                meta: {}
            },
            {
                path: 'eslestirme',
                name: 'amazon-eslestirme',
                component: () => import('@/views/amazon/matching/MatchingHome'),
                meta: {}
            },
            {
                path: 'pasifurun',
                name: 'amazon-pasifurun',
                component: () => import('@/views/amazon/passive-product/PassiveProductHome.vue'),
                meta: {}
            }
        ]
    }
]
