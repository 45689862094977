import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        categories: [],
    },
    getters: {
        _categories(state) {
            return state.categories;
        }
    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories
        }
    },
    actions: {
        all(context) {
            return axios.get('/supplier-category')
                .then(res => {
                    context.commit("setCategories", res.data)
                    return res.data;
                })
                .catch(error => {
                    alert('Tedarikçi Kategorilerini çekme hatası')
                    return error;
                })
        }
    }
}
