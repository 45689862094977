import {$themeBreakpoints} from '@themeConfig'

export default {
    namespaced: true,
    state: {
        taxes: [
            {text: '%0', value: 0, disabled: false},
            {text: '%1', value: 1, disabled: false},
            {text: '%8', value: 8, disabled: true},
            {text: '%10', value: 10, disabled: false},
            {text: '%18', value: 18, disabled: true},
            {text: '%20', value: 20, disabled: false},
        ],
        windowWidth: 0,
        shallShowOverlay: false,
    },
    getters: {
        currentBreakPoint: state => {
            const {windowWidth} = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },
        _taxes(state) {
            return state.taxes;
        }
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
    },
    actions: {},
}
