import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        publishers: [],
        selected: {publisher_name: "", status: 0},
        seach: {
            publisher_name: "",
        }
    },
    getters: {
        _publishers(state) {
            return state.publishers
        },
        _seach(state) {
            return state.seach;
        },
        _selected(state) {
            return state.selected;
        }
    },
    mutations: {
        setPublishers(state, publishers) {
            state.publishers = publishers
        },
        delPublisher(state, id) {
            let foundIndex = state.publishers.findIndex(item => parseInt(item.id) == parseInt(id));
            console.log(foundIndex);
            state.publishers.splice(foundIndex, 1);
        },
        addPublisher(state, publisher) {
            state.publishers.push(publisher);
        },
        setSelected(state, publisher) {
            state.selected = publisher
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/publisher/all', context.state.seach)
                .then(res => {
                    context.commit('setPublishers', res.data)
                    return res.data;
                })
                .catch(error => {
                    alert('Yayımevi Çekme Hatası')
                    return [];
                })
        },
        addNew(context, data) {
            return axios.post('/publisher', data)
                .then(res => {
                    context.commit('addPublisher', res.data);
                    return res.data
                })
                .catch(error => {
                    alert('Kategori Ekleme Hatası')
                    return error;
                })
        },
        destroy(context, id) {
            return axios.delete('/publisher/' + id)
                .then(res => {
                    if (res.data) {
                        context.commit('delPublisher', id)
                        return res.data;
                    }
                })
                .catch(erorr => {
                    alert('Yayımevi silme hatası')
                    return erorr;
                })
        }
    },
}
