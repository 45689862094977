import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        fields: [
            {key: 'id', label: 'Sıra No'},
            {key: 'code', label: 'Kısa Kod'},
            {key: 'name', label: 'Tedarikçi Adı'},
            {key: 'deafault_deadline', label: 'Termin (varsayılan)'},
            {key: 'deadlines', label: 'Terminler'},
            {key: 'description', label: 'Detaylar'},
            {key: 'date', label: 'Tarih'},
        ],
        suppliers: []
    },
    getters: {
        _suppliers(state) {
            return state.suppliers
        },
        _listSuppliers(state) {
            return state.suppliers.map(supplier => {
                return {text: supplier.name, value: supplier.id}
            });
        },
        _fields(state) {
            return state.fields;
        }
    },
    mutations: {
        setSuppliers(state, suppliers) {
            state.suppliers = suppliers
        }
    },
    actions: {
        suppliers(context) {
            axios.get('/suppliers')
                .then(res => {
                    context.commit('setSuppliers', res.data)
                })
                .catch(() => {
                        alert('Tedarikçi Çekme Hatası')
                    }
                )
        }
    },
}
