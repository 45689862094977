export default [
    {
        path: '/siparis',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'siparis',
                component: () => import('@/views/order/list/OrderListHome'),
                meta: {},
            },
            {
                path: 'faturasepeti',
                name: 'siparis-faturasepeti',
                component: () => import('@/views/order/list/OrderListHome'),
                meta: {
                    pageName: 'invoice',
                },
            },
            {
                path: 'hazirlaniyor',
                name: 'siparis-hazirlaniyor',
                component: () => import('@/views/order/list/OrderListHome'),
                meta: {
                    pageName: 'preparing'
                }
            },
            {
                path: 'toplanacak',
                name: 'siparis-toplanacak',
                component: () => import('@/views/order/list/OrderListHome'),
                meta: {
                    pageName: 'collet'
                }
            },

            {
                path: 'toplanacakx',
                name: 'siparis-toplanacakx',
                component: () => import('@/views/order/entry/OrderEntryHome'),
                meta: {
                    pageName: 'collet'
                }
            },
            {
                path: 'hazirlaniyorx',
                name: 'siparis-hazirlaniyorx',
                component: () => import('@/views/order/entry/OrderEntryHome'),
                meta: {
                    pageName: 'preparing'
                }
            }
        ]
    },
]
