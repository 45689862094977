import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        stocks: [],
        selected: [],
        search: {
            sorting: "DESC",
            orderBy: "created_at",
            stock_return: "",
            suppliers: [],
            shelve_name: "",
            product: {
                id: "",
                barcode: "",
                name: ""
            },
            limit: 10,
            page: 1,
        },
        pagination: null,
    },
    getters: {
        _stocks(state) {
            return state.stocks;
        },
        _selected(state) {
            return state.selected;
        },
        _pagination(state) {
            return state.pagination;
        },
        _search(state) {
            return state.search
        }
    },
    mutations: {
        setData(state, res) {
            state.stocks = [...res.data.data]
            delete res.data.data;
            state.pagination = res.data;
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/v2/stock', context.state.search)
                .then(res => {
                    context.commit('setData', res.data)
                    return true;
                })
                .catch(() => {
                    alert('stok v2 seçkme hatası')
                    return true;
                })
        },

    }
}
