import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        products: [],
    },
    getters: {
        _products(state) {
            return state.products
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
        addProducts(state, product) {
            state.products.push(product)
        },
        editProduct(state, product) {
            state.products.forEach(item => {
                if (item.request.date == product.date) {
                    item.stock = product.stock;
                }
            })
        }
    },
    actions: {
        getAll(context) {
            return axios.get('/stock/all')
                .then(res => {
                    context.commit('setProducts', res.data)
                })
                .catch(() => {
                    alert('stok seçkme hatası')
                })
        },
        addNewProduct(context, product) {
            return axios.post('/stock/taking/new', product)
                .then(res => {
                    context.commit('addProducts', res.data)
                    return true;
                })
                .catch(() => {
                    alert('stok sayım ekleme hatası')
                    return true;
                })
        },
        updateProdcut(context, data) {
            return axios.put('/stock/taking/edit/' + data.id, data)
                .then(res => {
                    context.commit('editProduct', res.data)
                    return true;
                })
                .catch(() => {
                    alert('stok düzeltme hatası')
                    return true;
                })
        }
    }
}
