import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        preOrderStatuses: []
    },
    getters: {
        _preOrderStatuses(state) {
            return state.preOrderStatuses;
        }
    },
    mutations: {
        setPreOrderStatuses(state, status) {
            state.preOrderStatuses = status
        }
    },
    actions: {
        getStatus(context) {
            axios.get('/preorder-status')
                .then(res => {
                    if (res.data.status == 'success') context.commit('setPreOrderStatuses', res.data.content)
                    else alert()
                })
        }
    }
}
