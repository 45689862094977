import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        orderDetail: {},
        orderOnemonth: null
    },
    getters: {
        _orderDetail(state) {
            return state.orderDetail;
        },
        _orderOnemonth(state) {
            return state.orderOnemonth;
        }
    },
    mutations: {
        setOrderDetail(state, detail) {
            state.orderDetail = detail
        },
        setOrderOnemonth(state, onemonth) {
            state.orderOnemonth = onemonth
        }
    },
    actions: {
        getOrderDetail(context) {
            context.commit('setOrderDetail', {})
            axios.get('home/order/detail')
                .then(res => {
                    context.commit('setOrderDetail', res.data.data)
                })
        },
        getOrderOnemonth(context) {
            context.commit('setOrderOnemonth', {})
            axios.get('home/order/onemonth')
                .then(res => {
                    context.commit('setOrderOnemonth', res.data.data)
                })
        }
    }
}
