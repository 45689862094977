import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        publishers: [],
    },
    getters: {
        _publishers(state) {
            return state.publishers;
        }
    },
    mutations: {
        setPublishers(state, publishers) {
            state.publishers = publishers
        }
    },
    actions: {
        all(context) {
            return axios.get('/supplier-publisher')
                .then(res => {
                    context.commit("setPublishers", res.data)
                    return res.data;
                })
                .catch(error => {
                    alert('Tedarikçi yayımevleri çekme hatası')
                    return error;
                })
        }
    }
}
