import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import matchStatus from './status/matchStatus'
import product from './product/index';
import productCategory from './product/category';
import productPublisher from './product/publisher'
import order from "@/store/order/index";
import orderStatus from "@/store/order/orderStatus";
import orderBasket from "@/store/order/orderBasket";
import orderCommonLabel from "@/store/order/orderCommonLabel";
import supplier from './supplier/index'
import supplierProduct from './supplier/product'
import supplierCategory from './supplier/category'
import suplierPublisher from './supplier/publisher';
import supplierAuthors from './supplier/authors';
import hbCampaign from './hepsiburada/campaign';
import hbProductMatching from "@/store/hepsiburada/hbProductMatching";
import tyProduct from './trendyol/index';
import tyStoreProduct from "@/store/trendyol/tyStoreProduct";
import tyCreateProduct from "@/store/trendyol/tyCreateProduct";
import azProductMatching from "@/store/amazon/azProductMatching";
import azStoreProduct from "@/store/amazon/azStoreProduct";
import store from './store/index';
import stock from '@/store/stock/index';
import stockV2 from '@/store/stock/v2/index';
import stockEntry from '@/store/stock/stockEntry'
import stocktaking from "@/store/stock/stocktaking";
import stockComfirm from "@/store/stock/stockComfirm";
import company from "@/store/company";
import current from "@/store/company/current";
import cargoCompany from "@/store/cargo-company/index";
import agreement from "@/store/agreement/index";
import agreementDetail from "@/store/agreement/agreementDetail";
import users from "@/store/user/index";
import home from "@/store/home/index"
import productBrand from "@/store/product/brand"
import question from "@/store/question";
import readyAnswer from "@/store/question/ReadyAnswer";
import orderQuestion from "@/store/question/orderQuestion";
import invoice from "@/store/invoice";
import preOrder from "@/store/pre-order/preOrder";
import preOrderStatus from "@/store/pre-order/preOrderStatus";
import stockRack from "@/store/v2/stock/rack";
import activeStock from "@/store/v2/stock/activeStock";
import stocktakingV2 from "@/store/v2/stock/stocktaking"
import stocktakingConfirm from "@/store/v2/stock/stocktakingConfirm";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        matchStatus,
        product,
        productCategory,
        productPublisher,
        productBrand,
        order,
        orderStatus,
        orderBasket,
        orderCommonLabel,
        supplier,
        supplierProduct,
        supplierCategory,
        suplierPublisher,
        supplierAuthors,
        hbCampaign,
        hbProductMatching,
        tyProduct,
        tyStoreProduct,
        tyCreateProduct,
        azProductMatching,
        azStoreProduct,
        preOrder,
        preOrderStatus,
        store,
        stock,
        stockV2,
        stockEntry,
        stocktaking,
        stockComfirm,
        company,
        current,
        cargoCompany,
        agreement,
        agreementDetail,
        users,
        home,
        question,
        readyAnswer,
        orderQuestion,
        invoice,
        stockRack,
        activeStock,
        stocktakingV2,
        stocktakingConfirm
    },
    strict: process.env.DEV,
})
