export default [
    {
        path: '/agreement',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'agreement',
                component: () => import('@/views/agreement/AgreementHome'),
                meta: {}
            },
            {
                path: 'detail',
                name: 'agreement-detail',
                component: () => import('@/views/agreement/detail/AgreementDetailHome'),
                meta: {}
            }
        ]
    }
]
