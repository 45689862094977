export default [
    {
        path: '/v2/stock',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'v2-stock',
                component: () => import('@/views/stock/v2/StockHome'),
            }
        ]
    },
    {
        path: '/stock',
        component: () => import('@/views/HomeRoute.vue'),
        meta: {},
        children: [
            {
                path: '/',
                name: 'stock-card',
                component: () => import('@/views/stock/card/StockCardHome'),
            },
            {
                path: 'entry',
                name: 'stock-entry',
                component: () => import('@/views/stock/entry/StokEntryHome'),
            },
            {
                path: 'comfirm',
                name: 'stock-comfirm',
                component: () => import('@/views/stock/confirm/StockComfirmHome'),
            },
            {
                path: 'taking',
                name: 'stok-taking',
                component: () => import('@/views/stock/stocktaking/StocktakingHome'),
            }
        ],
    },
]
