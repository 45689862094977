import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        toTree: [],
        toFlatTree: [],
        whereIsLeaf: []
    },
    getters: {
        _totree(state) {
            return state.toTree
        },
        _toflattree(state) {
            return state.toFlatTree
        },
        _whereIsLeaf(state) {
            return state.whereIsLeaf;
        }
    },
    mutations: {
        setToTree(state, payload) {
            state.toTree = payload;
        },
        addToTree(state, rack) {
            if (rack.parent_id) {

            } else {
                state.toTree.push(rack);
            }
        },
        setToFlatTree(state, payload) {
            state.toFlatTree = payload;
        },
        setWhereIsLeaf(state, paylod) {
            state.whereIsLeaf = paylod
        },
        putWhereIsLeaf(state, rack) {
            let findIndex = state.whereIsLeaf.findIndex(item => item.id === rack.id);
            if (findIndex > -1) {
                state.whereIsLeaf.splice(findIndex, 1, rack);
            }
        }
    },
    actions: {
        getToTree(context) {
            context.commit('setToTree', []);
            return axios.get('/v2/stock/rack/totree')
                .then(res => {
                    context.commit('setToTree', res.data.content);
                    return res.data.context;
                })
        },
        getToFlatTree(context) {
            context.commit('setToFlatTree', []);
            return axios.get('/v2/stock/rack/toflattree')
                .then(res => {
                    context.commit('setToFlatTree', res.data.content);
                    return res.data.context;
                })
        },
        getWhereIsLeaf(context) {
            context.commit('setToFlatTree', []);
            return axios.get('/v2/stock/rack/whereisleaf')
                .then(res => {
                    context.commit('setWhereIsLeaf', res.data.content);
                    return res.data.context;
                })
        },
        saveRackToTree(context, payload) {
            return axios.post('/v2/stock/rack/saverack', payload)
                .then(res => {
                    if (res.data.status === 'success') context.commit('addToTree', res.data.content)
                    return res.data;
                })
        },
        putStockRack(context, payload) {
            return axios.put(`/v2/stock/rack/${payload.id}`, payload)
                .then(res => {
                    if (res.data.status === 'success') {
                        context.commit('putWhereIsLeaf', res.data.content)
                    }
                    return res.data;
                })
        }
    }
}