export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('@/views/NotAuthorized.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/Test'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]
