export default [
    {
        path: '/v2/stock/entery',
        component: () => import('@/views/v2/stock/entry/EntryHome.vue'),
        meta: {},
    },
    {
        path: '/v2/stock/rack',
        component: () => import('@/views/v2/stock/rack/RackHome.vue'),
        meta: {},
    },
    {
        path: '/v2/stock/comfirm',
        component: () => import('@/views/v2/stock/confirm/ComfirmHome.vue'),
        meta: {},
    },
    {
        path: '/v2/stock/stocktaking',
        component: () => import('@/views/v2/stock/stocktaking/StocktakingHome.vue'),
        meta: {},
    },
    {
        path: '/v2/stock/stocktaking/comfirm',
        component: () => import('@/views/v2/stock/stoctaking-confirm/StocktakingConfirmHome.vue'),
        meta: {},
    }
]