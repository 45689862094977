import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        productSupplierScans: [],
        preOrders: [],
        pagination: {},
        filter: {
            supplier_ids: [],
            pre_order_status_ids: [],
            limit: 25,
            page: 1,
            orderBy: 'created_at',
            sorting: 'DESC'
        }
    },
    getters: {
        _preOrders(state) {
            return state.preOrders;
        },
        _productSupplierScans(state) {
            return state.productSupplierScans;
        },
        _filter(state) {
            return state.filter;
        },
        _pagination(state) {
            return state.pagination;
        }
    },
    mutations: {
        setPreOrders(state, preOrders) {
            state.preOrders = preOrders;
        },
        setAddProductSupplierScan(state, data) {
            let index = state.productSupplierScans.findIndex(psc => psc.product_id === data.product_id)
            if (index > -1) {
                state.productSupplierScans[index] = data.supplierScan;
            } else {
                state.productSupplierScans.push(data)
            }
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        }

    },
    actions: {
        getFilter(context) {
            return axios.post('/preorder/list', context.state.filter)
                .then(res => {
                    console.log(res.data)
                    if (res.data.status == 'success') {
                        context.commit('setPreOrders', res.data.content.data);
                        delete res.data.content.data;
                        context.commit('setPagination', res.data.content);
                        return true;
                    }
                })
        },
        putPreOrder(context, payload) {
            return axios.put('/preorder/' + payload.id, payload)
                .then(res => {
                    return res.data;
                })
        },
        productSupplierScan(context, productId) {
            return axios.get('/product/supplier/scan/' + productId)
                .then(res => {
                    if (res.data.status === 'success') {
                        context.commit('setAddProductSupplierScan', {
                            product_id: productId,
                            supplierScan: res.data.content
                        })
                        return res.data;
                    } else {
                        console.log('eee')
                    }
                })
        }
    }
}
