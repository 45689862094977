export default [
    {
        path: '/fatura',
        component: () => import('@/views/invoice/InvoiceHome.vue'),
        meta: {},
        children: [
            {
                path: 'gelen',
                name: 'fatura-gelen',
                component: () => import('@/views/invoice/inbox/InboxHome.vue'),
                meta: {},
            },
            {
                path: 'giden',
                name: 'fatura-istatistik',
                component: () => import('@/views/invoice/Statistics.vue'),
                meta: {},
            },
        ]
    }
]
